import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import Nuage from '@oxeva/nuage-sdk';

import { useForm } from 'react-hook-form';
import { ComplexButton, ErrorMessage } from '@nuage/ui-components';

// Organisms
import FormLanding from '@molecules/group/formLanding';
import MainLayout from '@organisms/main/mainLayout';
import DetailsCoupon from '@molecules/group/detailsCoupon';
import Footer from '@organisms/main/footer';

import ComplexField from '@molecules/interactive/complexField';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import NuageClient from '@utils/client';

import './landing.scss';

const Landing = ({ params }) => {
    const { _string } = lang;

    if (params.id === '[id]') { navigate('/'); }

    const timer = useRef(null);

    const {
        handleSubmit,
        control,
        formState: { errors },
        // setError,
        // clearErrors,
        // trigger,
    } = useForm({
        mode: 'onTouched',
    });

    const [clObj, setClObj] = useState(null);
    const [couponLanding, setCouponLanding] = useState({});
    const [landingLoading, setLandingLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorType, setErrorType] = useState(null);

    const [validMail, setValidMail] = useState(null);

    const stringLandingValue = useMemo(() => (
        couponLanding && couponLanding.value === 200
            ? `_${couponLanding.value}`
            : ''), [couponLanding]);

    const onSignin = useCallback(({ mail }) => {
        if (!clObj) { return; }

        if (!clObj || !couponLanding) { return; }
        clearTimeout(timer.current);
        setLoading(true);

        if (!clObj || !couponLanding) { return; }

        const invitation = new Nuage.Invitation({
            email: mail,
            coupon: couponLanding.name,
        });
        clObj.persist(invitation).onReady((errorInvitation) => {
            if (errorInvitation) {
                setLoading(false);
                switch (errorInvitation.status) {
                case 429: {
                    setErrorType('requestError');
                    break;
                }
                case 422: {
                    setErrorType('forbidden');
                    break;
                }
                default: {
                    setErrorType('alert');
                    break;
                }
                }
                timer.current = setTimeout(() => {
                    setErrorType(null);
                }, 3000);
            } else {
                setValidMail(mail);
                setSuccess(true);
                dispatchGtagEvent('submit', {
                    event_category: 'form',
                    event_label: 'compte',
                });
            }
        });
    }, [couponLanding, clObj]);

    useEffect(() => {
        if (!clObj) { return; }

        // To be reactivated when ready
        clObj.coupon({ name: params.id.toUpperCase() }).onReady((error, data) => {
            if (error || data.isUsed) {
                navigate('/');
            } else if (data.validUntil) {
                const validDate = new Date(data.validUntil);
                const now = new Date();
                if (validDate < now) {
                    navigate('/');
                } else {
                    setCouponLanding(data);
                    setLandingLoading(false);
                }
            } else {
                setCouponLanding(data);
                setLandingLoading(false);
            }
        });
    }, [params, clObj]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj.cl);
    }, []);

    return (
        <MainLayout
            title={_string('landing_titleTab', {
                money: couponLanding.value || 0,
            })}
            socials={_string('landing_socials')}
            footer={false}
            classTitle="landing"
        >
            <FormLanding
                title={_string(`landing_title${stringLandingValue}`, {
                    money: couponLanding.value,
                })}
                titleForm={_string('landing_form_title', {
                    money: couponLanding.value,
                })}
                confirmTitle={_string('landing_confirm_title')}
                confirmText={_string('landing_confirm_text', { mail: validMail })}
                infoForm={_string('landing_form_info')}
                loading={landingLoading}
                success={success}
                onSubmit={handleSubmit(onSignin)}
            >
                <div className="landing__field">
                    <ComplexField
                        name="mail"
                        id="mail"
                        rules={{
                            required: _string('landing_error_mail_invalid'),
                            pattern: {
                                // Official regex, do not need eslint
                                // eslint-disable-next-line no-control-regex
                                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                message: _string('landing_error_mail_invalid'),
                            },
                        }}
                        controlForm={control}
                        placeholder={_string('landing_form_mail_placeholder')}
                        noValidStateField
                        disabled={loading}
                    />
                </div>
                { errorType && (
                    <ErrorMessage opened>
                        {_string(`intro_${errorType}`)}
                    </ErrorMessage>
                )}
                <ComplexButton
                    onClick={handleSubmit(onSignin)}
                    disabled={Object.keys(errors).length !== 0 || errorType || loading}
                    type="submit"
                    color="secondary"
                    // size="large"
                    loading={loading}
                >
                    {_string('landing_form_btn')}
                </ComplexButton>
            </FormLanding>
            <div className="landing__info">
                <DetailsCoupon />
            </div>
            <Footer />
        </MainLayout>
    );
};

Landing.propTypes = {
    params: PropTypes.objectOf(PropTypes.string),
};

Landing.defaultProps = {
    params: null,
};

export default Landing;
